<template>
  <div class="form-custom section">
    <cube-form
      class="form-wrapper"
      :model="model"
      @submit="submitHandler"
    >
      <cube-form-group class="form-group">
        <cube-form-item :field="fields.clueName">
          <cube-input
            v-model="model.clueName"
            :readonly="true"
            placeholder="请选择"
          />
        </cube-form-item>
        <div class="after-space border-bottom-1px" />
        <cube-form-item :field="fields.trackType">
          <div class="field-wrapper">
            <cube-select
              v-model="model.trackType"
              class="cube-select"
              :options="fields.trackType.props.options"
              placeholder="请选择"
            />
            <i class="icon-joyo arrows">&#xe66c;</i>
          </div>
        </cube-form-item>
        <div class="after-space border-bottom-1px" />
        <cube-form-item :field="fields.trackDate">
          <div class="field-wrapper">
            <div
              class="cube-select"
              :class="changeColor(model.trackDate)"
              @click="showDatePicker"
            >
              {{ model.trackDate || "请选择" }}
            </div>
            <date-picker
              ref="datePicker"
              :min="[2000, 1, 1]"
              :max="[2050, 1, 1]"
              @select="datesSelectHandler"
            />
            <i class="icon-joyo arrows">&#xe66c;</i>
          </div>
        </cube-form-item>
        <cube-form-item :field="fields.textarea" />
        <div class="after-space border-bottom-1px" />
      </cube-form-group>
      <div class="bottom-postion">
        <cube-button
          type="submit"
          class="btn"
        >
          保存
        </cube-button>
      </div>
    </cube-form>
  </div>
</template>

<script>
import datePicker from '_c/date-picker';
import { clueTrackSave } from '_api/clue';
import { mapGetters } from 'vuex';
export default {
  components: {
    datePicker
  },
  data() {
    return {
      model: {
        orgId: '',
        clueId: '',
        clueName: '',
        trackType: '',
        trackDate: '',
        trackRemark: ''
      },
      fields: {
        clueName: {
          type: 'input',
          modelKey: 'clueName',
          label: '线索名称',
          props: {
            placeholder: '请输入',
            disabled: true
          },
          rules: {
            required: true
          }
        },
        trackType: {
          type: 'select',
          modelKey: 'trackType',
          label: '跟进类型',
          props: {
            options: []
          },
          rules: {
            required: true
          }
        },
        trackDate: {
          modelKey: 'trackDate',
          label: '跟进日期',
          rules: {
            required: true
          }
        },
        textarea: {
          type: 'textarea',
          modelKey: 'trackRemark',
          label: '跟进内容描述',
          props: { placeholder: '跟进内容描述', maxlength: 100 },
          rules: { required: true }
        }
      }
    };
  },
  computed: {
    ...mapGetters(['userInfo', 'dictList'])
  },
  mounted() {
    this.getDataOptions();
  },
  methods: {
    changeColor(value) {
      if (value !== '') {
        return 'color-333';
      }
      return 'color-ccc';
    },
    showDatePicker() {
      this.$refs.datePicker.show();
    },
    datesSelectHandler(selectedVal) {
      this.model.trackDate = selectedVal;
    },
    submitHandler(e) {
      e.returnValue = false;
      clueTrackSave(this.model).then(res => {
        if (res.flag) {
          this.$showToast('保存成功');
          this.$router.go(-1);
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    getDataOptions() {
      this.model.orgId = this.userInfo.postRoleResList[0].orgId;
      this.model.clueId = this.$route.params.clueId;
      this.model.clueName = this.$route.params.clueName;
      this.fields.trackType.props.options = this.dictList.trackType;
    }
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped>
.section {
  height: 100%;
  .form-wrapper {
    height: 100%;
    background-color: $color-F7;
    .form-group {
      background-color: $color-FFF;
      .field-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .cube-select {
          flex: 1;
          text-align: left;
        }
        .color-ccc {
          color: $color-CCC;
        }
        .color-333 {
          color: $color-333;
        }
        .arrows {
          color: $color-CCC;
          font-size: 11px;
        }
        .button {
          flex: 0 0 70px;
          color: $color-theme;
          font-weight: 400;
          font-size: 14px;
          text-align: right;
        }
      }
    }
  }
}
</style>
